import React from 'react';
import './App.css';
import { worksData } from './data';
import { Work } from './types';
import logoImage from './icon.png'

interface AppProps {
  logoSrc?: string;
  works?: Work[];
}

export default function App({ logoSrc = logoImage, works = worksData }: AppProps) {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logoSrc} className="App-logo" alt="そらのサイトのロゴ" />
        <h3>
          そらのサイトにようこそ！
        </h3>

        <p className='Title'>
          Links
        </p> 
        <div>
          <Blog />
          <Twitter />
        </div>

        <p className="Title">
          Works
        </p>
        
        <div className="works-container">
          {works.map((work) => (
            <div key={work.id} className="work-item">
              <img src={work.imageUrl} alt={work.title} className="work-image" />
              <div className="work-description">
                <h3>{work.title}</h3>
                <p>{work.description}</p>
              </div>
            </div>
          ))}
        </div>

      </header>
      <footer className="App-footer">
        <p>&copy; {new Date().getFullYear()} sora81dev All Rights Reserved.</p>
      </footer>
    </div>
  );
}

function Blog() {
  const handleClick = () => {
    //place later
  };

  return (
  <span className="Button-Blog" onClick={handleClick}>
    Blog
  </span>
  )
}

function Twitter() {
  const handleClick = () => {
    //place later
  };

  return (
  <span className="Button-Twitter" onClick={handleClick}>
    Twitter
  </span>
  )
}
