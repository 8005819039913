import { Work } from './types'
import CommingSoon from './images/works/Comming-Soon.png'
import ImageUrl_1 from './images/works/works-1.jpg'

export const worksData: Work[] = [
    {
        id: 1,
        title: "Shooting Game",
        description: "初めて作ったゲームです。",
        imageUrl: ImageUrl_1
    },
    {
        id: 2,
        title: "Comming Soon...",
        description: "何かが進行している....",
        imageUrl: CommingSoon
    },

    // 他の作品も同様に追加
];

// 使用